import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Gallery from '../components/Gallery'
import { getPersonalProjects } from './__generated__/getPersonalProjects'
import CollectionToggler from '../components/CollectionToggler'
import { spacing } from '../utils/presets'
import SEO from '../components/SEO'

export const StyledGallery = styled(Gallery)`
    ${spacing(
        [
            [45, 60, 90],
            [10, 10, 60],
        ],
        'padding'
    )}
`

export default function Personal({ location }) {
    const data: getPersonalProjects = useStaticQuery(graphql`
        query getPersonalProjects {
            allPersonalProjects: allMdx(
                filter: {
                    fields: { category: { eq: "personal" } }
                    frontmatter: {
                        images: { elemMatch: { featured: { eq: true } } }
                    }
                }
            ) {
                ...ProjectGalleryData
            }
        }
    `)
    const images = data.allPersonalProjects.edges.flatMap((p) =>
        p.node.frontmatter.images
            .filter((i) => i.featured)
            .map((i) => ({
                id: i.image.id,
                childImageSharp: i.image.childImageSharp,
                path: p.node.fields.path,
            }))
    )
    return (
        <Layout footer={<CollectionToggler location={location} />}>
            <SEO
                title="Personal"
                description="Eine ausgewählte Auswahl an persönliche Projekten"
                image={images[0] && images[0].childImageSharp.fluid.src}
            />
            <StyledGallery
                images={images}
                navigateToExtractor={(image) => `/${image.path}/stream`}
                navigationAnimConfig={{
                    swipe: true,
                    direction: 'up',
                    duration: 1,
                }}
            />
        </Layout>
    )
}
