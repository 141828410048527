import React from 'react'
import styled from 'styled-components'

import Link from './Link'
import { SharedContainer } from '../styledComponents'
import Text from './Text'
import { mq, spacing } from '../utils/presets'

const Container = styled(SharedContainer)`
    background-color: rgba(var(--themeBGrgba), 0.15);
    backdrop-filter: blur(70px);
`

const Button = styled(({ isActive, ...rest }) => <Link {...rest} />)`
    flex: 1;
    display: flex;
    border: ${(props) =>
        props.isActive ? '3px solid' : '3px solid transparent'};
    border-bottom: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    text-align: center;
    min-height: 45px;
    ${mq.tablet} {
        border: ${(props) =>
            props.isActive ? '4px solid' : '4px solid transparent'};
        border-bottom: none;
        min-height: 60px;
    }
`

const LeftButton = styled(Button)`
    border-left: none;
`

const RightButton = styled(Button)`
    border-right: none;
`

type Props = {
    location: Location
}

const CollectionToggler = ({ location: { pathname } }: Props) => {
    const isLeftActive = pathname.includes('/personal')
    return (
        <Container>
            <LeftButton
                isActive={isLeftActive}
                to={`/personal`}
                animationConfig={{
                    swipe: true,
                    duration: 1,
                    direction: 'left',
                }}
            >
                <Text
                    type={
                        isLeftActive ? ['h3', 'h3', 'h3'] : ['h4', 'h2', 'h2']
                    }
                >
                    PERSONAL
                </Text>
            </LeftButton>
            <RightButton
                isActive={!isLeftActive}
                to={`/commissioned`}
                animationConfig={{
                    swipe: true,
                    duration: 1,
                    direction: 'left',
                }}
            >
                <Text
                    type={
                        !isLeftActive ? ['h3', 'h3', 'h3'] : ['h4', 'h2', 'h2']
                    }
                >
                    COMMISSIONED
                </Text>
            </RightButton>
        </Container>
    )
}

export default CollectionToggler
